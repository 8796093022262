import { useEffect, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button, Collapse, Divider, Grid, Paper, Stack,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import * as yup from 'yup';

import SavedSearch from '@/components/icons/saved-search';
import SearchHistory from '@/components/landing/SearchBox/SearchHistory';
import renderField from '@/components/landing/SearchBox/utils/renderField';
import submitHandler from '@/components/landing/SearchBox/utils/submitHandler';
import { baseUrl } from '@/config';
import { useUserContext } from '@/contexts/User/UserContext';
import useLocalStorage from '@/hooks/useLocalStorage';

const SearchForm = ({ searchFields }) => {
  const [fields, setFields] = useState(searchFields.head);
  const [bodyFields, setBodyFields] = useState(searchFields.body);
  const [validationSchema, setValidationSchema] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [searchHistory] = useLocalStorage('searchbox_sq', '');
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const { userState } = useUserContext();
  const { account } = userState;
  const router = useRouter();

  useEffect(() => {
    const values = {};
    const inputs = [
      ...fields,
      ...bodyFields,
    ];

    inputs.forEach((field) => {
      if (field.name instanceof Object) {
        Object.values(field.name).forEach((n) => {
          values[n] = '';
        });
      } else {
        values[`${field.name}${field.priority || ''}`] = field.default;
      }
    });

    setInitialValues(Object.assign(values, { cg1: 1000, cg2: 1020 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const validation = {};
    const inputs = [
      ...fields,
      ...bodyFields,
    ];

    inputs.forEach((field) => {
      if (field.validable && field.limit) {
        const { multiplier, limit: { min, max } } = field;
        validation[`${field.name.min}`] = yup.number().integer('Csak egész számok írhatóak be').nullable()
          .min(min)
          .max(max / multiplier, field.note);
        validation[`${field.name.max}`] = yup.number().integer('Csak egész számok írhatóak be').nullable()
          .min(min, field.note)
          .max(max / multiplier, field.note)
          .when(`${field.name.min}`, {
            is: (val) => val > 0,
            then: () => yup.number().moreThan(yup.ref(`${field.name.min}`), field.note),
          });
      }
    });

    setValidationSchema(yup.object(validation));
  }, [fields, bodyFields]);

  const handleSubmit = (values) => {
    submitHandler(values, [...fields, ...bodyFields], router);
  };

  return (
    <Grid
      container
      component={Paper}
      p={4}
      direction="row"
      alignItems="center"
      sx={{ position: 'relative', zIndex: '2' }}
    >
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form style={{ width: '100%', paddingTop: '48px' }}>
          <Grid container spacing={4} mb={4}>
            {
                fields?.map((field) => renderField({
                  ...field, setFields, setBodyFields, pageType: 'Re',
                }))
              }
          </Grid>
          <Grid container mb={5}>
            <Grid item xs={12}>
              <Divider>
                <Button
                  onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
                  variant="text"
                  color="secondary"
                  endIcon={showAdvancedSearch ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                >
                  Részletes keresés
                </Button>
              </Divider>
            </Grid>
          </Grid>
          <Collapse
            in={showAdvancedSearch}
            data-testid="real-estate-advanced-fields-collapse"
          >
            <Grid container spacing={4} mb={4}>
              {
                  bodyFields?.map((bodyField) => renderField({
                    ...bodyField, setFields, setBodyFields, pageType: 'Re',
                  }))
                }
            </Grid>
          </Collapse>
          <Grid
            container
            direction={{ xs: 'column-reverse', lg: 'row' }}
            alignItems={{ lg: 'center' }}
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item xs={12} lg={8}>
              {
                  searchHistory?.realestate
                  && <SearchHistory searchHistory={searchHistory.realestate} />
                }
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent={account ? 'space-between' : 'flex-end'}
                spacing={4}
              >
                {
                    account
                    && (
                    <Button
                      href={`${baseUrl}/fiok/mentett_keresesek`}
                      color="secondary"
                      variant="text"
                      startIcon={<SavedSearch />}
                    >
                      Mentett kereséseim
                    </Button>
                    )
                  }
                <Button type="submit" color="primary">Keresés</Button>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  );
};

export default SearchForm;
